import Flatpickr from 'stimulus-flatpickr';

// you can also import a translation file
import { French } from 'flatpickr/dist/l10n/fr.js';

// import a theme (could be in your main CSS entry too...)
import 'flatpickr/dist/themes/dark.css'

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  initialize() {
    // sets your language (you can also set some global setting for all time pickers)
    this.config = {
      locale: French,
      monthSelectorType: 'static',
    };

    if (this.hasCustomTarget) {
      this.config = {
        ...this.config, // Merge with the base configuration
        closeOnSelect: false,
        onReady: this.addSubmitButton.bind(this),
      };
    }
  }

  get hasCustomTarget() {
    return this.element.dataset.flatpickrCustom === 'true';
  }

  addSubmitButton(selectedDates, dateStr, instance) {
    const submitButton = document.createElement('button');
    submitButton.textContent = 'ok';
    submitButton.classList.add('flatpickr-submit-button');
    submitButton.style.all = 'unset';
    submitButton.classList.add('dashboard-tl__shared-ok-button');
    // Append the button to the Flatpickr calendar container
    instance.calendarContainer.appendChild(submitButton);
    // Handle submit button click
    submitButton.addEventListener('click', () => {
      instance.calendarContainer.style.display = 'none';
      this.submitForm(instance.input);
    });
  }

  submitForm(inputElement) {
    const form = inputElement.closest('form'); // Locate the closest form element
    if (form) {
      form.submit(); // Programmatically submit the form
    } else {
      console.error("No form found for the Flatpickr input!");
    }
  }
}
