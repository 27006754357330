import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['total'];

  updateClaimCounter() {
    const count = parseInt(this.totalTarget.innerText);
    this.totalTarget.innerText = count - 1;
  }
}
