import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'activityRow'
  ];

  filterByStatus(e) {
    this.activityRowTargets.forEach(row => {
      if (row.dataset.status === e.currentTarget.dataset.status) {
        row.style.display = 'flex';
      } else {
        row.style.display = 'none';
      }
    });
  }

  resetFilter() {
    this.activityRowTargets.forEach(row => row.style.display = 'flex');
  }
}
