import { Controller } from 'stimulus';

export default class extends Controller {

  static targets = [
    'totalHours',
    'dayHours'
  ];

  decreaseTotalHoursFromShift(e) {
    this.totalHoursTarget.innerText = parseFloat(this.totalHoursTarget.innerText) - parseFloat(e.currentTarget.dataset.shiftTime)
  }

  increaseTotalHoursFromShift(e) {
    this.totalHoursTarget.innerText = parseFloat(this.totalHoursTarget.innerText) + parseFloat(e.currentTarget.dataset.shiftTime)
  }

  decreaseTotalHoursFromAllUserShifts(e) {
    this.totalHoursTarget.innerText = parseFloat(this.totalHoursTarget.innerText) - parseFloat(e.currentTarget.dataset.dayHours)
  }

  increaseTotalHoursFromAllUserShifts(e) {
    this.totalHoursTarget.innerText = parseFloat(this.totalHoursTarget.innerText) - parseFloat(e.currentTarget.dataset.dayHours) + parseFloat(e.currentTarget.dataset.maxShiftsHours)
  }

  decreaseHours(e) {
    const dayHours = this.#getDayHours(e)
    dayHours.innerText = parseFloat(dayHours.innerText) - parseFloat(e.currentTarget.dataset.shiftTime)
    this.totalHoursTarget.innerText = parseFloat(this.totalHoursTarget.innerText) - parseFloat(e.currentTarget.dataset.shiftTime)
  }

  increaseHours(e) {
    const dayHours = this.#getDayHours(e)
    dayHours.innerText = parseFloat(dayHours.innerText) + parseFloat(e.currentTarget.dataset.shiftTime)
    this.totalHoursTarget.innerText = parseFloat(this.totalHoursTarget.innerText) + parseFloat(e.currentTarget.dataset.shiftTime)
  }

  decreaseHoursBatch(e) {
    const dayHours = e.currentTarget.parentElement.previousElementSibling;
    this.totalHoursTarget.innerText = parseFloat(this.totalHoursTarget.innerText) - parseFloat(dayHours.innerText);
    dayHours.innerText = 0.0;
  }

  increaseHoursBatch(e) {
    const dayHours = e.currentTarget.parentElement.previousElementSibling;
    this.totalHoursTarget.innerText = parseFloat(this.totalHoursTarget.innerText) - parseFloat(dayHours.innerText) + parseFloat(e.currentTarget.dataset.maxShiftsHours);
    dayHours.innerText = e.currentTarget.dataset.maxShiftsHours;
  }

  #getDayHours(e) {
    return this.dayHoursTargets.find(target =>
      parseInt(target.dataset.day.split('-')[2]) === parseInt(e.currentTarget.dataset.day.split('-')[2])
    );
  }
}
